import { IndicatorCalculation, MigrationTools } from 'entities/dataFamily';
import { AggregationType, CalculationType, NodesAggregationType, ValueRound } from 'entities/dataValue';
import { LogEventActionType, LogEventDataKind } from 'entities/logs';
import { ManipulationModal } from 'entities/menuNavigation';
import { Background, BlockSizing, ChartTypeSetting, SlideShowTimings, TextIcons, TextSize } from 'entities/panel';
import { Permission } from 'entities/user';
import { HseIncidentTypeEnum } from 'features/Pyramid/InputTable/model/HSEInputDataResponse';
import { DateTime } from 'luxon';
import { DefaultDateFormat } from 'shared/config/dates';
import { CalendarStep, InputType, ManipulationTypes, RangePeriod, TimeInterval, WeekDays } from 'shared/types';

const ru = {
  Common: {
    Autocomplete: 'Введите или выберите из списка',
    Actions: 'Действия',
    Cnt: 'Кол-во',
    Add: 'Добавить',
    All: 'Все',
    Active: 'Активно',
    Archive: 'Архивировать',
    Apply: 'Применить',
    Back: 'Назад',
    Description: 'Описание',
    From: 'c',
    To: 'по',
    UntilNow: 'н.в.',
    Till: 'до',
    Error: 'Ошибка',
    Save: 'Сохранить',
    SaveChanges: 'Сохранить изменения',
    SaveAndGo: 'Сохранить и перейти ?',
    Move: 'Переместить',
    Cancel: 'Отмена',
    Cancel2: 'Отменить',
    Search: 'Поиск',
    Details: 'Подробнее',
    Сlone: 'Дублировать',
    Closed: 'Закрыто',
    Close: 'Закрыть',
    Copy: 'копия',
    Delete: 'Удалить',
    Navigate: 'Перейти',
    Edit: 'Редактировать',
    Export: 'Экспорт',
    Change: 'Изменить',
    Configure: 'Настроить',
    Create: 'Создать',
    Name: 'Название',
    ClassifierOrNode: 'Классификатор/Узел',
    Filter: 'Фильтр',
    Loading: 'Загрузка...',
    RestoreDefaults: 'Cбросить',
    RemoveAcceptance: 'Подтверждение удаления',
    Proceed: 'Продолжить',
    Restore: 'Восстановить',
    Write: 'Записать',
    Settings: 'Настройки',
    Start: 'Начало',
    End: 'Конец',
    NotSelected: 'Не выбран',
    NoData: '—',
    Retry: 'Повторить',
    Unavailable: 'Недоступна',
    Unavailable2: 'Недоступен',
    SelectAll: 'Выбрать всё',
    DeselectAll: 'Снять всё',
    Last: 'Последние',
    Degree: {
      Celsium: 'C',
    },
    WeekDay: ((): Record<WeekDays, string> => ({
      Monday: 'Понедельник',
      Tuesday: 'Вторник',
      Wednesday: 'Среда',
      Thursday: 'Четверг',
      Friday: 'Пятница',
      Saturday: 'Суббота',
      Sunday: 'Воскресенье',
    }))(),
    WeekDayShort: ((): Record<WeekDays, string> => ({
      Monday: 'Пн',
      Tuesday: 'Вт',
      Wednesday: 'Ср',
      Thursday: 'Чт',
      Friday: 'Пт',
      Saturday: 'Сб',
      Sunday: 'Вс',
    }))(),
    AreWeekDays: 'Будние дни',
    IsWeekend: 'Выходные дни',
    IsEveryDay: 'Каждый день',
  },
  Pages: {
    Startup: {
      AppLoadingText: 'Идёт загрузка приложения...',
      NoConnection: (countdown: number) => `Нет подключения к серверу.\nПовтор через ${countdown} с.`,
    },
    NotFound: {
      Title: 'Страница не найдена',
      Subtitle: 'Страница отсутствует или у вас нет доступа.',
      GoMain: 'На главную',
    },
  },
  Blocks: {
    CommonSettings: {
      Title: 'Настройки инфоблока',
      Plan: 'План',
      Fact: 'Факт',
      ConfirmModalText: 'Для перехода необходимо сохранить текущие изменения. Сохранить и перейти?',
      ValueCalculation: 'Методика значения для периода',
      TooltipValueCalculation:
        'Позволяет выбрать методику обработки значений нескольких столбцов для одной строки в таблице ввода (пропорционально или целое).',
      ValueCalculationOptions: (type?: CalculationType) => {
        switch (type) {
          case 'Proportional': {
            return 'Пропорционально';
          }
          case 'Whole': {
            return 'Целое';
          }
          default: {
            return 'Как в показателе';
          }
        }
      },
      AggregationType: 'Тип агрегации',
      AggregationTypeTooltip:
        'Позволяет определить способ агрегации строк в таблицы ввода для дочерних значений классификатора (сумма, среднее арифметическое, последнее значение и др.)',
      AggregationTypeOptions: (type?: AggregationType) => {
        switch (type) {
          case 'Average': {
            return 'Среднее';
          }
          case 'Last': {
            return 'Последнее';
          }
          case 'Sum': {
            return 'Сумма';
          }
          default: {
            return 'Как в показателе';
          }
        }
      },
      ValueRound: 'Округление значений',
      ValueRoundOptions: ((): Record<ValueRound, string> => ({
        whole: 'Целое',
        tens: 'Десятые',
        hundreds: 'Сотые',
        thousands: 'Тысячные',
      }))(),
      NodesAggregationTypeOptions: (type?: NodesAggregationType) => {
        switch (type) {
          case 'Off': {
            return 'Выключена';
          }
          case 'Average': {
            return 'Среднее';
          }
          case 'Min': {
            return 'Минимальное';
          }
          case 'Max': {
            return 'Максимальное';
          }
          case 'Sum': {
            return 'Сумма';
          }
          default: {
            return 'Как в показателе';
          }
        }
      },
    },
    Card: {
      Name: 'Карточка',
      Description:
        'Инфоблок для отображения одного агрегированного значения по набору классификаторов семейства данных за выбранный промежуток времени.\nИнфоблок поддерживает режим проваливания по разрезам классификаторов.',
      DefaultSettings: {
        Title: 'Карточка',
      },
      SettingsFields: {
        Common: {
          Title: 'Общие',
          Name: 'Название инфоблока',
          Description: 'Описание',
          CommonOffset: 'Общее смещение',
          DaysCnt: 'Кол-во дней',
          Comments: 'Комментарии',
          Header: 'Шапка инфоблока',
          Subheader: 'Подзаголовок',
          Text: 'Текст',
        },
        Visuals: {
          Title: 'Отображение',
          Sizing: 'Размерность инфоблока',
          Size: 'Размер',
          SizeOptions: ((): Record<BlockSizing, string> => ({
            Cell: 'Ячейка',
            Small: 'Малый',
            Medium: 'Средний',
            Large: 'Крупный',
            Presentation: 'Для презентаций',
          }))(),
          Format: {
            Main: 'Разделять разряды числа',
            Tooltip: `Настройка разделяет классы числа\n(единицы, тысячи, милионы и т.д.)\nПример:\n"1234567890,12345"\n"1 234 567 890,12345"\nНастройка применяется только к основному показателю`,
          },
          MainIndicatorOptions: ((): Record<'Plan' | 'Fact' | 'Percent', string> => ({
            Plan: 'План',
            Fact: 'Факт',
            Percent: 'Процент',
          }))(),
          MainIndicator: 'Основной показатель',
        },
        Data: {
          Title: 'Данные',
          SplitValues: 'разбить значения',
          DataFamily: 'Семейство данных',
          Value: (index: number) => `Значение ${index}`,
          SelectionPeriod: 'Период выборки',
          PlanSelectionPeriod: 'Период выборки плана',
          FactSelectionPeriod: 'Период выборки факта',
          AddData: 'Добавить значение',
          AggregationType: 'Тип агрегации',
          PeriodValueMetodics: 'Методика значения для периода',
          FamilyDataSelector: 'Семейство',
          FormulaDataSelector: 'Формула',
          ChangeMainDate: 'Переопределить опорную дату',
          ByPeriod: {
            Title: 'Временной шаг',
            Step: 'Шаг',
          },
        },
        Indicator: {
          Title: 'Индикатор',
          Show: 'отображать',
          TrendSelectionPeriod: 'Период выборки тренда',
        },
        Percent: {
          Title: 'Процент',
          ToggleShow: 'отображать процент',
        },
        Dynamics: {
          Title: 'Динамика',
          Show: 'отображать',
        },
        Classifiers: {
          Title: 'Режим проваливания',
          ChildrenSumEnabled: 'агрегация по дочерним',
          DrillDown: {
            EnabledToggle: 'режим проваливания',
            DrillDownOrder: 'Порядок проваливания',
            AddLevel: ' Добавить уровень',
            NoClassifiersPlaceholder: 'Выберите источник данных',
          },
        },
      },
      DrillDown: {
        Title: (title?: string) => `Режим просмотра${title ? ': ' + title : ''}`,
        AggregatedValue: 'Агрегированное значение',
      },
      Tooltip: {
        MainValue: (value: string | number | undefined | null = '-', dateEnd: string | undefined | null = '-') =>
          `Значение показателя: ${String(value)}. На дату: ${String(dateEnd)}`,
        Target: (value: string | number | undefined | null = '-', dateEnd: string | undefined | null = '-') =>
          `Целевое значение показателя: ${String(value)}. На дату: ${String(dateEnd)}`,
        Trend: (value: string | number | undefined | null = '-') =>
          `Динамика показателя к предыдущему значению: ${String(value)}.`,
        TargetPercent: (
          currentValue: string | number | undefined | null = '-',
          target: string | number | undefined | null = '-',
          percent: string | number | undefined | null = '-'
        ) => `Достижение цели: ${String(currentValue)} из ${String(target)} или ${String(percent)} %`,
      },
    },
    InputTable: {
      Name: 'Показатели',
      Description: 'Инфоблок для ввода и просмотра значений семейств данных в разрезах классификаторов.',
      Template: 'Шаблон',
      Presets: {
        MainDeleteText: (name: string) => `Шаблон "${name}" будет удалён!`,
      },
      NoData: 'Данных для отображения нет',
      NoDataChangeClassification: 'Данных для отображения нет, измените классификацию в фильтре',
      NotSelected: 'Не выбран',
      NotSelected2: 'Не выбрано',
      Logs: {
        Title: 'История изменений',
        Headers: {
          DateTime: 'Дата, время',
          Action: 'Действие',
          User: 'Пользователь',
          Indicator: 'Показатель',
          Classifier: 'Классификатор',
          Period: 'Период',
          Source: 'Источник',
          Value: 'Значение',
        },
        InputTypes: ((): Record<InputType, string> => ({
          ManualInput: 'Пользователь',
          Xlsx: 'Excel',
          Integration: 'API',
          AutoCalculated: 'Расчётное',
        }))(),
      },
      Dialogs: {
        SaveToProceedDialog: {
          Title: 'Сохраните изменения',
          Subtitle: 'Для продолжения необходимо сохранить текущие изменения!',
        },
      },
      Actions: {
        Upload: {
          Import: 'Импорт',
          Success: 'Файл успешно экспортирован',
          Dialog: {
            Title: 'Подтверждение записи данных',
            TitleMessages: 'Сообщения',
            Total: (failed: number, success: number) =>
              `Значений к записи: ${success}${failed ? ' из ' + (failed + success).toString() + ' (есть ошибки)' : ''}`,
            ProceedMessages: {
              Message1:
                'Данные из Excel файла с успешно найденными классификаторами, типами данных, корректными периодами и значениями будут импортированы.',
              Message2:
                'Если в ячейке файла значение 0, то оно будет записано. Соответствующее ранее введенное значение (при наличии) будет перезаписано числом 0.',
              Message3:
                'Если ячейка файла пустая, то новое значение не будет записано. Соответствующее ранее введенное значение (при наличии) будет удалено.',
            },
          },
          Errors: {
            InvalidFormat: 'Неверный формат файла.',
            MaxSizeExceeded: (size: number) => `Файл должен быть не более ${size} МБ`,
            ErrorWhenUploading: 'При экспорте файла произошла ошибка',
          },
        },
      },
      Placeholder: {
        SelectDataFamily: 'Выберите семейство данных',
        NetworkError: 'Ошибка доступа к данным',
      },
      Classifiers: {
        FilterBy: (root: string) => `Фильтр по "${root}"`,
      },
      Plan: {
        Plan: 'План',
        Dialog: {
          DataSavedDialog: {
            Title: 'Переход к планированиию',
            Message: 'Для работы с планированием необходимо сохранить текущую сессию!',
          },
          RemoveValueDialog: {
            Title: 'Подтверждение удаления',
            Message: 'Вы уверены, что хотите удалить данные?',
          },
          Title: 'Планирование',
        },
        Messages: {
          DataSaved: 'Данные успешно сохранены',
          HasFutureData: 'Данные внесены на ещё не наступившую дату',
        },
      },
      Table: {
        NavigationPopup: {
          Title: (name: string) => `Быстрый переход “${name}”`,
          Up: 'Наверх',
        },
        Cell: {
          Popup: {
            ChildrenSum: 'Сумма по дочерним',
          },
        },
      },
      Filter: {
        Title: 'Фильтр',
        Periods: {
          Title: 'Отображать колонки',
          Year: 'Год',
          Month: 'Месяц',
          Day: 'Дни/недели',
          Fully: 'Целиком',
        },
        Breakdown: {
          Title: 'Разбивка',
          Year: 'Год',
          Month: 'Месяц',
          Week: 'Неделя',
        },
        Classification: {
          Title: 'Классификация',
          Full: 'Полная',
          Used: 'Задействованная',
          UsedWithChildren: 'Задействованная по дочерним',
        },
        WithChildrenSum: 'Суммировать по дочерним',
      },
    },
    Text: {
      Title: 'Текст',
      Description: 'Инфоблок для создания заголовков и текстовых описаний на панели.',
      Settings: {
        Alignment: 'Выравнивание',
        Style: 'Стиль текста',
        WordWrap: 'перенос строки',
        TextSize: 'Размер текста',
        TextSizes: ((): Record<TextSize, string> => ({
          Small: 'Маленький',
          Medium: 'Средний',
          Large: 'Большой',
          ExtraLarge: 'Очень большой',
          Presentation: 'Для презентаций',
        }))(),
        ShowAppendIcon: 'Иконка в начале',
        ShowPrependedIcon: 'Иконка в конце',
        Background: 'Фон',
        Icons: ((): Record<TextIcons, string> => ({
          Cargo: 'Автотранспорт',
          Ship: 'Водный траспорт',
          Train: 'Ж/Д Транспорт',
          TrendNegative: 'Отрицательная динамика',
          TrendPositive: 'Положительная динамика',
        }))(),
        Backgrounds: ((): Record<Background, string> => ({
          None: 'Без фона',
          Default: 'Обычный',
          Header: 'Заголовок',
        }))(),
        Divider: 'разделительная полоса',
      },
    },
    Folder: {
      Title: 'Папка/Слайд-шоу',
      Description: 'Инфоблок для встраивания панели в панель и отображения панелей в режиме слайдшоу.',
      SlideShowMode: 'режим слайд-шоу',
      Header: (name?: string, slideShow = false, slideIndex = 0, total = 0) => {
        if (name) {
          return slideShow ? `Слайд ${slideIndex}/${total}: ${name}` : name;
        } else {
          return slideShow ? 'Слайд-шоу' : '';
        }
      },
      Settings: {
        Folder: {
          Panel: 'Панель',
          ExternalDate: 'выбор даты',
          ExternalClassifier: 'выбор классификатора',
          RootClassifier: 'Корневой классификатор',
          Classifiers: 'Классификаторы',
        },
        SlideShow: {
          Panels: 'Панели',
          ShowTime: 'Время показа',
        },
      },
    },
    Pyramid: {
      Title: 'Пирамида',
      Description:
        'Инфоблок для отображения информации с учетом ее важности (от низкого приоритета до высокого) на диаграмме пирамиды.',
      Settings: {
        Common: {
          Title: 'Общие',
          Name: 'Название инфоблока',
        },
        Data: {
          Title: 'Данные',
          SplitValues: 'разбить значения',
          RangePeriod: 'Период выборки',
          WithChildrenSum: 'Сумма по дочерним',
        },
        Visuals: {
          Title: 'Отображение',
          ShowHeader: 'шапка инфоблока',
          ShowIncidents: 'инциденты',
          DaysCounter: {
            ShowCounter: 'счётчик',
            Text: 'Текст',
            Levels: 'Уровни классификаторов',
          },
          Pyramid: 'пирамида',
          LayersOrder: 'Порядок классификаторов',
        },
      },
      InputTable: {
        Title: 'Ввод данных по происшествиям HSE',
        Headers: {
          Geography: 'География',
          Type: 'Тип',
          Date: 'Дата',
          Count: 'Кол-во',
          Description: 'Описание',
          Actions: 'Действия',
        },
        Incidents: (count: number) => `Инциденты (${count})`,
        AddIncident: 'Добавить происшествие',
        IncidentTypes: ((): Record<HseIncidentTypeEnum, string> => ({
          Death: 'Смерть',
          Disability: 'НС с ВПТ',
          MedicalCare: 'МП на производстве + ПП',
          NearMiss: 'ПбП + ДТП + Алко + Аварии',
          DangerWarning: 'Опасные случаи и действия',
        }))(),
        RemoveDialog: {
          Title: 'Подтверждение удаления',
          Confirm: (title: string) => `Запись ${title} будет удалена!`,
        },
        AddEditDialog: {
          Title: (mode: ManipulationTypes) => {
            return mode === 'add' ? 'Создание происшествия' : 'Изменение происшествия';
          },
        },
        ExportDialog: {
          Title: 'Экспорт происшествий',
          Confirm: 'Экспортировать',
        },
      },
    },
    Weather: {
      Title: 'Погодные ограничения',
      Description:
        'Инфоблок отображения погоды, а так же для ввода и отображения метеоусловий, влияющих на производственный процесс.',
      Item: {
        Temperature: 'Температура',
        WindSpeed: 'Скорость ветра',
        Description: 'Актировка',
      },
      Settings: {
        Title: 'Настройки',
        Information:
          'По умолчанию отображаются все классификаторы, если хотите ограничить список, выберите нужные "Классификаторы" в списке ниже.',
      },
      Restrictions: {
        InputTable: {
          Title: 'Ввод погодных ограничений',
          Headers: {
            Geography: 'География',
            Start: 'Начало',
            End: 'Окончание',
            Temperature: 'Температура',
            WindSpeed: 'Скорость ветра',
            WeightLift: 'Грузоподъёмность',
            Description: 'Актировка',
            Hours: 'Кол-во часов',
            Status: 'Статус',
            Actions: 'Действия',
          },
          RemoveDialog: {
            Title: 'Удалить ограничение',
            Confirm: 'Удалить погодное ограничение?',
          },
          AddEditDialog: {
            Title: (mode: ManipulationTypes) =>
              mode === 'add' ? 'Создание ограничения' : 'Редактирование ограничения',
            Classifier: 'Классификатор (локация)',
          },
          ExportDialog: {
            Date: 'Дата',
            Title: 'Экспорт погодных ограничений',
            Confirm: 'Экспортировать',
            Cancel: 'Отменить',
          },
        },
      },
    },
    CommentsLegacy: {
      Title: 'Комментарии',
      Description: 'Инфоблок позволяет оставлять комментарии на панели в привязке к дате/времени и пользователю.',
      Settings: {
        ShowName: 'Отображать название',
        PeriodFilter: 'фильтр по периоду',
        HideDetails: 'Скрыть автора и дату',
      },
      Comment: {
        OnDate: (date: DateTime) => `На дату: ${date.toFormat(DefaultDateFormat)}`,
        Placeholder: 'Введите комментарий...',
        SelectDate: 'Выберите дату',
        SelectDateSubtitle: 'Выберите нужную дату для вашего комментария',
        Date: 'Дата',
      },
    },
    Diagram: {
      Category: {
        Title: 'График',
        Description: 'Визуальное представление данных на координатной плоскости для анализа и сравнения информации.',
        Settings: {
          Visuals: {
            Title: 'Визуализация',
            Subtitle: (type: ChartTypeSetting) => {
              switch (type) {
                case 'column':
                  return 'Вертикальная диаграмма (столбчатый барчарт)';
                case 'columnStacked':
                  return 'Барчарт';
                case 'columnStackedFull':
                  return 'Cтолбчатый барчарт с накоплением';
                case 'bar':
                  return 'Горизонтальная диаграмма (столбчатый барчарт)';
                case 'barStacked':
                  return 'Барчарт';
                case 'barStackedFull':
                  return 'Cтолбчатый барчарт с накоплением';
                case 'pie':
                  return 'Круговая диаграмма (Пайчарт)';
                case 'donut':
                  return 'Кольцевой график';
                case 'area':
                  return 'График областей';
                case 'line':
                  return 'Линейная диаграмма (график)';
              }
            },
            Description: (type: ChartTypeSetting) => {
              switch (type) {
                case 'column':
                  return 'Позволяет сравнивать числовую метрику по нескольким категориям (значениям классификатора).';
                case 'columnStacked':
                  return '';
                case 'columnStackedFull':
                  return 'Барчарт с накоплением';
                case 'bar':
                  return 'Позволяет сравнивать числовую метрику по нескольким категориям (значениям классификатора), применима при длинных названиях категорий.';
                case 'barStacked':
                  return '';
                case 'barStackedFull':
                  return 'Барчарт с накоплением';
                case 'pie':
                  return 'Круг, разделённый на сегменты, показывает доли от целого.';
                case 'donut':
                  return 'Окружность с пустым центром, разделённая на сегменты, показывает доли от целого.';
                case 'area':
                  return 'Показывает динамику изменений метрики в зависимости от какого-то фактора (например, времени или значения классификатора).';
                case 'line':
                  return 'Показывает динамику изменений метрики в зависимости от какого-то фактора (например, времени или значения классификатора).';
              }
            },
            Theme: 'Тема оформления графиков',
            LineGrid: 'Линия сетки',
            Horizontal: 'Горизонтальная',
            Vertical: 'Вертикальная',
            LineStyles: 'Стиль',
            DottedLine: 'Пунктирная',
            SolidLine: 'Сплошная',
            Width: 'Ширина (px)',
            DataLabels: {
              Title: 'Метки данных',
              Direction: 'Ориентация',
              HorizontalDirection: 'По горизонтали',
              VerticalDirection: 'По вертикали',
              Font: 'Шрифт',
              Size: 'Размер',
              Color: 'Цвет',
              Position: {
                Title: 'Положение',
                TopInside: 'У вершины внутри',
                TopOutside: 'У вершины снаружи',
                CenterInside: 'По центру внутри',
                BaseInside: 'У основания внутри',
              },
              Background: 'Фон',
              Opacity: 'Прозрачность',
              Value: 'Значение',
              ShowValues: 'Показывать единицы',
              Mode: 'Режим отображения',
              ModeTypes: {
                OnlyNumber: 'Только число',
                OnlyPercent: 'Только процент',
                NumberAndPercent: 'Число и Процент',
              },
              Fraction: 'Число десятичных знаков',
              TypesValue: {
                None: 'Нет',
                Thousand: 'Тыс',
                Million: 'Млн',
                Milliard: 'Млрд',
                Trillion: 'Трлн',
              },
            },
            Format: {
              Main: 'Разделять разряды числа',
              Tooltip: `Настройка разделяет классы числа\n(единицы, тысячи, милионы и т.д.)\nПример:\n"1234567890,12345"\n"1 234 567 890,12345"\nНастройка применяется только к основному показателю`,
            },
          },
          Data: {
            AddSerie: 'Добавить серию',
            Serie: (index: string) => `Серия ${index}`,
            Color: 'Цвет',
          },
        },
      },
    },
  },
  Features: {
    AdvancedPeriodSelector: {
      PeriodTypes: {
        Static: 'Статичный',
        Sliding: 'Скользящий',
      },
      Static: {
        Title: 'Статический',
        Year: 'год',
        StopByDate: 'ограничить опорной датой',
      },
      Sliding: {
        Title: 'Скользящий',
        CalendarValue: 'календарное значение',
        Interval: 'Интервал',
        WeekStart: 'Начало недели',
        Quarter: 'Квартал',
        Offset: 'Смещение',
        WeekDays: ((): Record<WeekDays, string> => ({
          Monday: 'Понедельник',
          Tuesday: 'Вторник',
          Wednesday: 'Среда',
          Thursday: 'Четверг',
          Friday: 'Пятница',
          Saturday: 'Суббота',
          Sunday: 'Воскресенье',
        }))(),
        RangePeriod: ((): Record<RangePeriod, string> => ({
          day: 'День',
          week: 'Неделя',
          month: 'Месяц',
          fullQuarter: 'Квартал (накопительно)',
          quarter: 'Квартал',
          year: 'Год',
        }))(),
        PeriodSteps: ((): Record<CalendarStep, string> => ({
          Day: 'День',
          Week: 'Неделя',
          Month: 'Месяц',
          Quarter: 'Квартал',
          Year: 'Год',
        }))(),
        QuarterName: (quarter: number) => {
          if (quarter === 0) {
            return `Текущий`;
          }

          return `${quarter} квартал`;
        },
      },
      Begin: 'Начало',
      End: 'Окончание',
    },
    Panels: {
      CreatePanelSucces: (panelName: string) => `Панель “${panelName}”\nуспешно добавлена`,
      CopyInfoblockSuccess: (panelName: string) => `Инфоблок скопирован на панель\n“${panelName}”`,
      PanelNotCompatible: {
        Title: 'Панель не адаптирована под мобильные устройства',
      },
      TemplateMenu: {
        Title: 'Шаблон меню',
        Placeholder: 'Найти шаблон',
        TemplateCommon: 'Общие',
        TemplateMy: 'Мои',
        NoTemplates: 'Нет шаблонов',
        NothingFound: 'Ничего не найдено',
        SpecifyRequest: 'Уточните запрос',
        WithoutGroups: 'Без группы',
      },
      Dialogs: {
        ExpExcelPanelDialog: {
          Title: 'Экспорт в Excel',
          Export: 'Экспортировать',
          Done: 'Экспорт завершен',
          Error: 'Ошибка при экспорте',
          NoData: 'Нет данных для экспорта',
          DataFamily: 'Семейство данных',
          Classifiers: 'Классификаторы',
          Period: 'Период',
          Tooltip: `Порядок классификаторов влияет на отображение в выгрузке.\nСамый верхний классификатор, будет первой колонкой и т.д.\nПотянув за точки вы можете менять порядок элементов.`,
          WithChildrenSum: 'Суммировать по дочерним',
        },
        RemovePanelDialog: {
          Title: 'Подтверждение удаления',
          DialogText: (name: string) => `Панель "${name}" будет удалена!`,
          DialogGroupText: (name: string) => `Шаблон "${name}" будет удалён!`,
          DialogGroupText2Part1: 'Группа',
          DialogGroupText2Part2: 'будет удалена, все панели будут перемещены в группу “Без группы”',
        },
        CreateUpdatePanelDialog: {
          Title: (mode: ManipulationTypes) => {
            switch (mode) {
              case 'edit': {
                return 'Изменение шаблона';
              }
              case 'add':
              default: {
                return 'Создание шаблона';
              }
            }
          },
          Role: 'Роли',
        },
        Error: 'Произошла ошибка',
        CreateCopyEditPanelDialog: {
          Tags: 'Теги панели',
          Title: (mode: ManipulationTypes) => {
            switch (mode) {
              case 'copy': {
                return 'Дублирование панели';
              }
              case 'edit': {
                return 'Настройки панели';
              }
              case 'add':
              default: {
                return 'Создание панели';
              }
            }
          },
        },
        CreateUpdateMoveGroupDialog: {
          Title: (mode: ManipulationModal) => {
            switch (mode) {
              case 'move': {
                return 'Перемещение выбранного';
              }
              case 'edit': {
                return 'Изменение шаблона';
              }
              case 'editTemplate': {
                return 'Изменение шаблона';
              }
              case 'editGroup': {
                return 'Изменение группы';
              }
              case 'createGroup': {
                return 'Создание группы';
              }
              case 'saveTemplate': {
                return 'Сохранение шаблона';
              }
              case 'create':
              default: {
                return 'Создание шаблона';
              }
            }
          },
          Group: 'Группа',
        },
      },
      EditPanel: {
        PanelSettings: {
          Title: 'Настройки отображения',
          Mobile: 'Отображать на телефоне',
          RowHeightTitle: 'Высота строки',
          ColumnCount: 'Столбцы',
          Margin: 'Расстояние между блоками',
          DefaultsButton: 'Сбросить настройки',
          AddOnPanel: 'Добавить на панель',
        },
      },
      StartEditing: 'Редактировать',
      PanelsList: {
        AddPanel: 'Создать панель',
        EditMode: 'Режим редактирования',
        Panels: 'Панели',
        Settings: 'Настройки панели',
        Personal: 'Личная панель',
      },
      AddPanelInvitation: 'Добавьте нужные инфоблоки, для этого перейдите в режим редактирования панели',
      Infoblocks: 'Инфоблоки',
    },
    UserInfo: {
      Settings: 'Настройки',
      AccountManagement: 'Профиль пользователя',
      LogOut: 'Выход',
    },
    Notifications: {
      Title: 'Уведомления',
      NoNotifications: 'Нет новых уведомлений',
      ContextMenu: {
        ReadAll: 'Отметить все прочитанным',
        ClearAll: 'Очистить все уведомления',
        TurnOnNotifications: 'Включить уведомления',
        TurnOffNotifications: 'Выключить уведомления',
      },
    },
    References: {
      Title: 'Справочники',
      Classifiers: {
        Add: 'Добавить',
        TooltipEnter: 'Вставьте или введите значения, разделенные символом «;», нажмите «Enter».',
        TooltipParentsInput:
          'Выберите «Родителей», если хотите создать узел. Если нужен классификатор, данное поле не заполняется.',
        Title: 'Классификаторы',
        FamilyData: 'Семейство данных',
        PeriodData: 'Период данных',
        Parents: 'Родители',
        NodeData: (nodeName: string) => `Данные на узле “${nodeName}”`,
        AddEditDialog: {
          Title: (mode: ManipulationTypes) => `${mode === 'add' ? 'Создание' : 'Настройка'} классификатора`,
        },
        RestoreDialog: {
          Title: 'Восстановление классификатора',
          Subtitle: (name: string) => `Восстановить ранее архивированный классификатор "${name}"?`,
        },
        RemoveDialog: {
          Title: 'Архивация узла',
          MainText: (title: string) => `Вы хотите отправить узел "${title}" в архив?`,
          SecondaryText: 'Все связи данного узла будут разорваны и узел будет недоступен!',
          AlertText: (nodeTitle: string) => `Узел “${nodeTitle}” будет удален, текущие связи
будут разорваны!`,
          ChildNodesCount: 'Количество дочерних узлов',
          ChildNodesValueRecordsCount: 'Количество записей на дочерних узлах',
          ValueRecordsCount: 'Количество записей на узле',
          IndicatorClassifiersCount: 'Количество задействованных семейств данных',
          HasHseIncidents: 'По узлу найдены данные по происшествиям HSE',
          HasWeatherRestrictions: 'По узлу найдены погодные ограничения',
        },
      },
      DataFamilies: {
        Title: 'Семейства данных',
        CalculationType: ((): Record<IndicatorCalculation, string> => ({
          Interval: 'Сумма на дату вывода',
          Momentary: 'Последнее введенное значение',
        }))(),
        RemoveDialog: {
          Title: 'Подтверждение удаления',
          MainText: (title: string) => `Вы хотите отправить семейство данных "${title}" в архив?`,
        },
        RestoreDialog: {
          Title: 'Восстановление данных',
          MainText: (title: string) => `Семейство данных “${title}” будет восстановлено!`,
        },
        AddEditDialog: {
          Title: (mode: ManipulationTypes) => `${mode === 'add' ? 'Создание' : 'Настройка'} семейства данных`,
          EditClassifiersMessage: 'Для изменения классификаторов воспользуйтесь мастером миграции',
          OutputRule: 'Правило вывода',
        },
        MigrationMaster: {
          Title: 'Мастер миграции',
          AddNodes: {
            Title: 'Добавление привязок',
            Indicator: 'Показатель',
            RootsList: 'Список корней',
            Placeholder: 'Выберите семейство данных',
            Notification: 'Для распределения данных выберите узел классификатора.',
            SpreadData: 'Распределите данные',
          },
          DataTransfer: {
            Title: 'Перенос данных',
            InitialDataFamily: 'Исходное семейство данных',
            ResultDataFamily: 'Результирующее семейство данных',
            Warning: {
              FirstParagraph:
                'Распределение введенных данных удаляемого показателя по выбранному графу будет безвозвратно удалено.',
              SecondParagraph: 'Уже введенные значения потенциально могут стать пересекающимися по периодам. ',
              ThirdParagraph: 'Операция может занять длительное время.',
            },
          },
          ApplyAction: ((): Record<MigrationTools, string> => ({
            AddNodes: 'Мигрировать',
            DataTransfer: 'Начать перенос',
          }))(),
        },
      },
      Formulas: {
        Title: 'Формулы',
        NullDataLabel: 'Формулы еще не созданы',
        AddEditDialog: {
          Title: (mode: ManipulationTypes) => `${mode === 'add' ? 'Создание' : 'Настройка'} формулы`,
          EditClassifiersMessage: 'Для изменения классификаторов воспользуйтесь мастером миграции',
          OutputRule: 'Правило вывода',
          Variables: 'Переменные',
          Formula: 'Формула',
          Tags: 'Теги',
        },
        DeleteDialog: {
          Title: 'Подтверждение удаления',
          Message: (name = '') => `Формула ${name} будет удалена`,
        },
        EditVariableDialog: {
          Title: 'Настройка данных',
        },
      },
    },
    CommonActions: {
      CopyBlock: {
        Title: 'Дублировать на панель',
        DuplicateSuccess: (panelName: string) => `Создан дубликат панели\n“${panelName}”`,
        DuplicateError: (panelName: string) => `Ошибка дублирования панели\n“${panelName}”`,
      },
    },
  },
  Settings: {
    Title: 'Настройки системы',
    Contexts: {
      Title: 'Контексты данных',
      CurrentContext: 'Текущий контекст',
      LeaveContextDialog: {
        Title: 'Покинуть контекст',
        PrimaryText: 'Вы уверены, что хотите покинуть данный контекст?',
        SecondaryText: (name: string) => `При выходе из контекста “${name}”
          вы потеряете доступ ко всем данным!`,
        Leave: 'Покинуть',
      },
      AddEditContextDialog: {
        AddModeTitle: 'Создание контекста',
        EditModeTitle: 'Настройка контекста',
        UseAsCurrent: 'Переключиться на данный контекст',
      },
      Table: {
        Name: 'Название',
        PanelsCount: 'Панелей',
        Current: 'Текущий',
        Author: 'Автор',
      },
    },
    Users: {
      Title: 'Пользователи',
      AllRoles: 'Все роли',
      NoUsers: 'Пользователей еще нет',
      RemoveDialog: {
        Title: 'Подтверждение удаления',
        Text: (name: string) => `Пользователь "${name}" будет удалён!`,
      },
      AddEditUserDialog: {
        AddModeTitle: 'Добавление пользователя',
        EditModeTitle: 'Изменение пользователя',
        User: 'Пользователь',
        Role: 'Роль',
      },
      Table: {
        Role: 'Роль',
        ID: 'ID',
      },
    },
    Templates: {
      AddEditTemplate: {
        Title: (mode: ManipulationTypes) => `${mode === 'add' ? 'Создание' : 'Настройка'} шаблона`,
        Tags: 'Теги шаблона',
      },
    },
    MenuNavigation: {
      Title: 'Меню навигации',
      TemplateNavigation: 'Шаблоны меню еще не созданы',
      ChooseTemplateCurrent: 'Использовать как текущий',
      AddEditMenuTemplate: {
        Title: (mode: ManipulationTypes) => `${mode === 'add' ? 'Создание' : 'Изменение'} шаблона`,
      },
      MenuNavigationControl: {
        CreateGroup: 'Создать группу',
      },
    },
    Roles: {
      Title: 'Роли',
      NoRoles: 'Роли еще не созданы',
      DeleteRole: {
        Title: 'Подтверждение удаления',
        Message: (name: string) => `Роль "${name}" будет удалена!`,
      },
      AddEditRole: {
        Title: (mode: ManipulationTypes) => `${mode === 'add' ? 'Создание' : 'Настройка'} роли`,
        RoleTemplate: 'Шаблон роли',
        RoleTags: 'Теги',
        Permissions: {
          Title: 'Разрешения роли',
          Available: 'Доступные',
          Selected: 'Выбрано',
          Setup: {
            Title: 'Настройки разрешения',
            SelectPanelTags: 'Выберите теги панелей',
            SelectTemplateTags: 'Выберите теги шаблонов',
          },
        },
      },
    },
    Logs: {
      Title: 'Лог действий',
      Headers: {
        DateTime: 'Дата, время',
        SessionId: 'ID сессии',
        Account: 'Учетная запись',
        UserIP: 'IP-пользователя',
        ServerIP: 'IP-сервера',
        RequestURL: 'URL-адрес запроса',
        ObjectType: 'Объект',
        Action: 'Действие',
        Data: 'Данные',
      },
      Actions: ((): Record<LogEventActionType, string> => ({
        Add: 'Добавление',
        Update: 'Обновление',
        Delete: 'Удаление',
        Migrate: 'Миграция',
        Archive: 'Архивирование',
        Unarchive: 'Восстановление из архива',
        Login: 'Логин',
        Logout: 'Логаут',
        Navigation: 'Навигация',
        Export: 'Экспорт',
        GrantAccess: 'Предоставление доступа',
        RevokeAccess: 'Отзыв доступа',
        Other: 'Прочее',
      }))(),
      Objects: ((): Record<LogEventDataKind, string> => ({
        User: 'Пользователь',
        Context: 'Контекст',
        Role: 'Роль',
        Panel: 'Панель',
        IndicatorData: 'Данные по показателям',
        Other: 'Прочее',
      }))(),
      Export: {
        Title: 'Экспорт',
      },
      Filters: {
        ObjectsTitle: 'Объекты',
        ActionsTitle: 'Действия',
        UserAccounts: 'Учётные записи',
        DatePeriod: 'Дата / Период',
        SessionId: 'ID сессии',
        UserIP: 'IP-пользователя',
      },
    },
    Notifications: {
      Title: 'Оповещения',
      NoNotifications: 'Оповещения еще не созданы',
      Headers: {
        Name: 'Название',
        Shedule: 'Расписание',
        Time: 'Время',
        Recipients: 'Получатели',
        Panel: 'Панель',
        Author: 'Автор',
      },
      DeleteDialog: {
        Title: 'Подтверждение удаления',
        Message: (title: string) => `Оповещение “${title}” будет удалено!`,
      },
      AddEditDialog: {
        Title: (mode: ManipulationTypes) => `${mode === 'add' ? 'Создание' : 'Настройка'} оповещения`,
        Notification: 'Уведомление',
        Classifiers: 'Классификаторы',
        DataFamilies: 'Семейства данных',
        Report: 'Отчёт',
        PickMonthDays: 'Указать числа месяца',
        ExcludeWeekend: 'Исключить выходные',
        TimeInterval: 'Временной интервал',
        TimeSent: 'Время отправки',
        MonthDays: 'Числа месяца (через запятую)',
        Shedule: 'Расписание',
        TimeIntervals: ((): Record<TimeInterval, string> => ({
          Yesterday: 'за вчера',
          LastSevenDays: 'за последние 7 дней',
          LastMonth: 'за последний месяц',
        }))(),
        Recipients: 'Получатели оповещений',
        RoleRecipients: 'Получатели оповещений по ролям',
        Panel: 'Панель для быстрого перехода',
        RedirectImmediately: 'Перейти к панели после создания',
        SendImmediately: 'Отправить сразу после создания',
      },
    },
  },
  Entities: {
    Context: {
      NewContextName: 'Новый контекст',
    },
    Data: {
      Period: {
        Start: 'Начало периода',
        End: 'Конец периода',
      },
      Value: {
        Title: 'Значение',
      },
    },
    Permissions: ((): Record<Permission, string> => ({
      CreatePanels: 'Создание панелей',
      CreateBlockTemplates: 'Создание шаблона',
      CreateNotifications: 'Создание оповещений для других',
      CreateOwnNotifications: 'Создание личных оповещений',
      DoEverything: 'Полный доступ',
      EditClassifiers: 'Редактирование всех узлов классификатора',
      EditClassifiersStarting: 'Редактирование некоторых узлов классификатора',
      EditPanelsClassifiedStarting: 'Редактирование панелей на классификаторах',
      EditPanelTagsClassifiedStarting: 'Редактирование тегов панелей',
      EditValues: 'Редактирование значений',
      EditValuesClassifiedStarting: 'Редактирование данных по показателям для выбранных классификаторов',
      EditBlockTemplatesClassifiedStarting: 'Редактирование шаблона на классификаторах',
      ViewClassifiers: 'Просмотр узла классификатора',
      ViewClassifiersStarting: 'Просмотр некоторых узлов классификатора',
      ViewLogs: 'Просмотр логов системы',
      ViewPanelsClassifiedStarting: 'Просмотр общих панелей на классификаторах',
      ViewBlockTemplatesClassifiedStarting: 'Просмотр шаблона на классификаторах',
      ViewValues: 'Просмотр значений',
      ViewValuesClassifiedStarting: 'Просмотр данных по показателям для выбранных классификаторов',
    }))(),
    Chart: {
      Errors: {
        Main: 'Ошибка в конфигурации графика',
      },
    },
  },
  Roundings: {
    Thou: 'тыс',
  },
  Units: {
    SavedColors: 'Сохраненные цвета',
    NoSavedColors: 'Нет сохраненных цветов',
    Pieces: 'шт',
    Kg: 'кг',
    Meters: 'м',
    Centimeters: 'см',
    Meters3: 'м3',
    CubeSign: '3',
    MeterPerSec: 'м/с',
    Timings: ((): Record<SlideShowTimings, string> => ({
      tenSecs: '10 секунд',
      twentySecs: '20 секунд',
      fiftySecs: '50 секунд',
      oneMinute: '1 минута',
      threeMinutes: '3 минуты',
      fiveMinutes: '5 минут',
    }))(),
  },
  Validation: {
    Messages: {
      Required: 'Обязательное поле',
      Email: 'Неверный формат почты',
      Phone: 'Неверный формат номера телефона',
      InvalidFormat: 'Неверный формат',
    },
  },
  Errors: {
    Classifier: {
      Title: 'Ошибка в узле классификатора',
    },
    Startup: {
      Title: 'Ошибка запуска приложения',
    },
    Contexts: {
      ContextsList: {
        BaseError: 'Ошибка в таблице контекстов',
      },
      LoadError: {
        Title: 'Ошибка при загрузки контекстов',
      },
    },
    Notifications: {
      Title: 'Ошибка в списке оповещений',
    },
    InputTable: {
      NextDataLoadError: {
        Title: 'Ошибка загрузки данных',
      },
      DataSaveError: {
        Title: 'Ошибка при сохранении данных',
      },
    },
    Panels: {
      BaseError: 'Ошибка панели.',
      CreatePanelError: (panelName: string) => `Не удалось создать панель\n“${panelName}”`,
      LoadError: {
        Title: 'Ошибка загрузки панелей',
      },
      UpdateError: {
        Title: 'Ошибка при сохранении настроек панели',
      },
      Blocks: {
        UpdateError: 'Ошибка при сохранении настроек инфоблока',
        BaseError: 'Ошибка инфоблока.',
        CopyInfoblockError: (panelName: string) => `Ошибка при копировании на\nпанель “${panelName}”`,
      },
    },
    Users: {
      LoadError: {
        Title: 'Ошибка загрузки данных о пользователе',
      },
      UsersList: {
        BaseError: 'Ошибка в таблице пользователей',
      },
      Roles: {
        RolesList: {
          BaseError: 'Ошибка в таблице ролей',
        },
      },
    },
    Templates: {
      LoadError: {
        Title: 'Ошибка при загрузке шаблонов',
      },
    },
  },
};

export default ru;
